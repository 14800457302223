<template>
  
  <div class="ediciones" style="padding:0%;margin-bottom: 100px;
   clear: both;">
    
    <div class="container" style="margin-top: 5%;">
        <div class="row">
            <div style="margin-top: 3%;">
                <img src="../images/segundavirginidad.jpg" alt="" class="rounded mx-auto d-block img-fluid">
            </div>
            
            <h2 style="color:white">Segunda Virginidad</h2>
            <h3 style="color:white;">Fernanda Ballesteros</h3>
            <h6 style="color:white;">Editorial Paraíso Perdido</h6>
            <p style="color:white; text-align:justify">Bajo la promesa de una segunda virginidad, Isabela, una adolescente que no ha experimentado el enamoramiento ni del sexo, vive sus primeros encuentros amorosos, enfrentando sus deseos, inseguridades y obsesiones, hasta finalmente conocer el desamor. En medio de un círculo católico y conservador, se enfrenta con cuatro relaciones que la obligarán a caminar sobre una delgada línea que separa sus deseos más fervientes de las ideas que tiene sobre el pecado, la pérdida, la esperanza, la nostalgia, el cuerpo propio y el acto político. A través de un viaje íntimo, Isabela traza un manifiesto donde confronta las ideas preconcebidas del feminismo, la sexualidad y la virginidad.</p>
            <br>
            <div class="">
                <a href="https://wa.me/526621699804/?text=¡Quiero%20mi%20Segunda%20Virginidad!" class="btn" role="button" style="background-color:#075e54; color:white; width: 200px;" target="_blank">Pedir por WhatsApp</a>
            </div>
            
        </div>
    </div>
    
    
  </div>
</template>
<script>
    export default {
      
    }
</script>